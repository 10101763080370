<template>
  <div id="simulation-list-page">
    <section class="for-rt">
      <div class="container">
        <div class="room-title" v-if="$store.getters.userType == 'master'">Simulation Room List</div>
        <div class="room-title" v-if="$store.getters.userType == 'student'">Joined Simulation</div>
      </div>
    </section>

    <section class="show-item">
      <div class="container">
        <div class="filter-row">
          <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
          <div class="button-filter">
            <div class="bf-item mx-2">
              <select class="form-control select-filter" v-model="region">
                <option :value="null">Region</option>
                <option v-for="(region, index) in regions" :key="index" :value="region.id">{{ region.name }}</option>
              </select>
            </div>
            <div class="bf-item">
              <select class="form-control select-filter" v-model="status">
                <option :value="null">Status</option>
                <option v-for="(status, index) in statuses" :key="index" :value="status.id">{{ status.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="room-items">
      <div class="container">
        <template v-for="(simulation, index) in simulations">
          <div class="dashboard-item main-list mb30" :key="index">
            <div class="row g-2 align-items-center">
              <div class="col-lg-7">
                <div class="f-sim-col">
                  <div class="row align-items-center">
                    <div class="col-sm-4">
                      <div class="simulation-img">
                        <figure>
                          <template v-if="$store.getters.userType == 'master'">
                            <template v-if="simulation.status_simulation > 0">
                              <router-link :to="`/simulation/${simulation.slug}`">
                                <img :src="simulation.banner_url" class="img-fluid">
                              </router-link>
                            </template>
                            <template v-else>
                              <a href="#" @click="startId = simulation.id">
                                <img :src="simulation.banner_url" class="img-fluid">
                              </a>
                            </template>
                          </template>
                          <template v-else>
                            <template v-if="simulation.status_simulation > 0">
                              <router-link :to="`/simulation/${simulation.slug}`">
                                <img :src="simulation.banner_url" class="img-fluid">
                              </router-link>
                            </template>
                            <template v-else>
                              <a href="#">
                                <img :src="simulation.banner_url" class="img-fluid">
                              </a>
                            </template>
                          </template>
                        </figure>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <template v-if="$store.getters.userType == 'master'">
                        <template v-if="simulation.status_simulation > 0">
                          <router-link :to="`/simulation/${simulation.slug}`" class="db-news-title mb-3">
                            {{ simulation.subject_topic }}
                          </router-link>
                        </template>
                        <template v-else>
                          <a href="#" @click="startId = simulation.id" class="db-news-title mb-3">
                            {{ simulation.subject_topic }}
                          </a>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="simulation.status_simulation > 0">
                          <router-link :to="`/simulation/${simulation.slug}`" class="db-news-title mb-3">
                            {{ simulation.subject_topic }}
                          </router-link>
                        </template>
                        <template v-else>
                          <a href="#" class="db-news-title mb-3">
                            {{ simulation.subject_topic }}
                          </a>
                        </template>
                      </template>
                      <div class="row align-items-center">
                        <div class="col-sm-5">
                          <div class="participant">
                            <div>Current Participant</div>
                            <div class="mx-2">:</div>
                            <div class="dark"><strong>{{ simulation.total_participant_user }}</strong></div>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <div class="participant country">
                            <div>Country List</div>
                            <div class="mx-2">:</div>
                            <div><strong>{{ simulation.total_participant_country }}</strong></div>
                            <div class="mx-3">
                              <template v-if="$store.getters.userType == 'master'">
                                <template v-if="simulation.status_simulation > 0">
                                  <router-link :to="`/simulation/${simulation.slug}`" class="btn btn-rd">
                                    <span class="icon-arrow-right"></span>
                                  </router-link>
                                </template>
                                <template v-else>
                                  <a href="#" @click="openId = simulation.id" class="btn btn-rd">
                                    <span class="icon-arrow-right"></span>
                                  </a>
                                </template>
                              </template>
                              <template v-else>
                                <template v-if="simulation.status_simulation > 0">
                                  <router-link :to="`/simulation/${simulation.slug}`" class="btn btn-rd">
                                    <span class="icon-arrow-right"></span>
                                  </router-link>
                                </template>
                                <template v-else>
                                  <a href="#" class="btn btn-rd">
                                    <span class="icon-arrow-right"></span>
                                  </a>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="row align-items-center">
                  <div class="col-sm-3 col-6">
                    <div class="date">Simulation Date</div>
                    <div class="date-info">{{ simulation.simulation_date }}</div>
                  </div>
                  <div class="col-sm-5 col-6">
                    <div class="d-flex justify-content-center">
                      <div class="box-status ongoing">{{ simulation.status_simulation_text }}</div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-6">
                    <template v-if="$store.getters.userType == 'master'">
                      <template v-if="simulation.status_simulation == 0">
                        <div class="for-bi">
                          <a href="#" class="btn btn-icon" @click="openId = simulation.id">
                            <span class="icon-ico-eye"></span> Open
                          </a>
                        </div>
                      </template>
                      <template v-else>
                        <div class="for-bi">
                          <router-link :to="`/simulation/${simulation.slug}`" class="btn btn-icon">
                            <span class="icon-ico-eye"></span> Open
                          </router-link>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="simulation.status_simulation == 0">
                        <div class="for-bi">
                          <a href="#" class="btn btn-icon mb-2">
                            <span class="icon-ico-eye"></span>
                            View
                          </a>
                        </div>
                      </template>
                      <template v-else>
                        <div class="for-bi">
                          <router-link :to="`/simulation/${simulation.slug}`" class="btn btn-icon mb-2">
                            <span class="icon-ico-eye"></span>
                            View
                          </router-link>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="d-flex justify-content-center">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </section>

    <!-- popup confirm open registration phase simulation -->
    <div class="popup-overlay" :class="{ show: openId}">
      <div class="inner-delete" :class="{ popup: openId}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to open registration phase this room ?</p>
        <p>you will be fully responsible for all simulation sessions in this room</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="openId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="openRegistration">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "SimulationList",
  data() {
    return {
      simulations: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      statuses: [
        {
          id: 0,
          name: "Waiting to Start"
        },
        {
          id: 1,
          name: "Registration Phase"
        },
        {
          id: 2,
          name: "Waiting Approval"
        },
        {
          id: 3,
          name: "Ongoing Simulation"
        },
        {
          id: 4,
          name: "Finished"
        }
      ],
      status: null,
      regions: [],
      region: null,
      openId: null,
    }
  },
  mounted() {
    if (this.$route.query.status !== undefined) {
      if (Number(this.$route.query.status) >= 0 && Number(this.$route.query.status) <= 4) {
        this.status = Number(this.$route.query.status)
      }
    }
    this.fetchRegions();
    this.fetchSimulations();
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  watch: {
    currentPage(){
      this.fetchSimulations()
    },
    status() {
      if (this.currentPage == 1) {
        this.fetchSimulations()
      } else {
        this.currentPage = 1
      }
    },
    region() {
      if (this.currentPage == 1) {
        this.fetchSimulations()
      } else {
        this.currentPage = 1
      }
    }
  },
  methods: {
    fetchRegions() {
      this.$axios.get('/cms/region', {
        params: {
          per_page: 100,
          sortBy: 'name',
          sortType: 'asc'
        }
      })
        .then(response => {
          this.regions = response.data.data.data
        })
    },
    fetchSimulations() {
      let urlRequest = null
      if (this.$store.getters.userType == 'student') {
        urlRequest = '/student/simulation'
      } else {
        urlRequest = '/master/simulation'
      }
      this.$axios.get(urlRequest, {
        params: {
          page: this.currentPage,
          per_page: this.perPage,
          search: this.filter,
          sortBy: this.sortBy,
          sortType: (this.sortDesc)? 'asc' : 'desc',
          status_simulation: this.status,
          region_id: this.region
        }
      })
        .then(response => {
          this.simulations = response.data.data.data
          this.totalRows = response.data.data.meta.total
        })
    },
    openRegistration() {
      this.$axios.patch('/master/room/open_registration', {
        id: this.openId,
      })
        .then(response => {
          Swal.fire("Success", response.data.message, "success");
          this.openId = null
          this.fetchSimulations()
        })
    },
  }
}
</script>